<template>
  <div>
    <div>
      <a-form :form="form" :wrapperCol="{xxl:20,xl:15}" :labelCol="{xxl:4,xl:9}">
        <a-row gutter="20">
          <a-col
            :xxl="6">
            <a-form-item
              label="姓名">
              <a-input></a-input>
            </a-form-item>
          </a-col>
          <a-col
            :xxl="8">
            <a-form-item>
              <a-button type="primary" icon="search" html-type="submit">搜索</a-button>
            </a-form-item>

          </a-col>
          <a-col
            :xxl="10">
            <div class="float-right">
              <a-button type="primary" icon="plus" @click="addStaff">添加员工</a-button>
            </div>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="ant-card">
      <s-table
        ref="table"
        :data="loadData"
        :columns="columns">
        <span slot="status" slot-scope="text, record">
          <a-tag v-if="record.status==1" color="green">启用</a-tag>
          <a-tag v-else color="red">禁用</a-tag>
        </span>
        <span slot="sex" slot-scope="text, record">
          {{ record.sex==0?'女':'男' }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a style="color: red" @click="deleteThisStaff(record.id)">删除</a>
          <a-divider type="vertical" />
          <a @click="editThisStaff(record)">编辑</a>
        </span>
      </s-table>
    </div>

  </div>
</template>

<script>

import { STable } from '@/components'
import { deleteStaff, loadStaffList } from '@/api/pc'
import EditStaff from '@/views/staff/EditStaff'
export default {
  name: 'StaffList',
  components: {
    STable
  },
  data () {
    return {
      form: this.$form.createForm(this),
      columns: [
        {
          title: '姓名',
          dataIndex: 'name',
          align: 'center'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          align: 'center',
          scopedSlots: { customRender: 'sex' }
        },
        {
          title: '服务人数',
          dataIndex: 'customer_num',
          align: 'center'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          align: 'center',
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          align: 'center',
          width: 180,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }],
      queryParams: {},
      loadData: parameter => {
        return loadStaffList(Object.assign(this.queryParams, parameter)).then(res => {
          return res.result
        })
      }
    }
  },
  methods: {
    deleteThisStaff (id) {
      var _this = this
      this.$confirm({
        title: '提示',
        content: '确认删除该员工吗？',
        onOk: function () {
          deleteStaff({ id: id }).then(res => {
            if (res.code === 0) {
              _this.$refs.table.refresh()
            }
          })
        }
      })
    },
    editThisStaff (record) {
      this.showModal(EditStaff, '编辑员工信息', 400, 400, { record: record })
    },
    addStaff () {
      this.showModal(EditStaff, '编辑员工信息', 400, 400)
    },
    refreshTable (goFirst) {
      this.$refs.table.refresh(goFirst)
    }
  }
}
</script>

<style scoped>

</style>
