<template>
  <div style="width: 100%;padding: 8px;">
    <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" @submit="handleSubmit">
      <a-form-item label="姓名">
        <a-input v-decorator="['name', { rules: [{ required: true, message: '请输入姓名!' }] }]"></a-input>
      </a-form-item>
      <a-form-item label="性别">
        <a-select v-decorator="['sex', { rules: [{ required: true, message: '请选择性别!' }] }]">
          <a-select-option value="0">女</a-select-option>
          <a-select-option value="1">男</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input v-decorator="['phone', { rules: [{ required: true, message: '请输入手机号!' }] }]"></a-input>
      </a-form-item>
      <a-form-item label="用户名">
        <a-input v-decorator="['account', { rules: [{ required: true, message: '请输入用户名!' }] }]" :disabled="disabled"></a-input>
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-decorator="['password', { rules: [{ required: true, message: '请输入登录密码!' }] }]"></a-input>
      </a-form-item>
      <a-form-item :wrapper-col="{ offset: 5 }">
        <a-button type="primary" html-type="submit">保存</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import md5 from 'md5'
import { saveStaffInfo } from '@/api/pc'
export default {
  name: 'EditAdmin',
  props: ['record', 'layerid'],
  data () {
    return {
      form: this.$form.createForm(this),
      disabled: true
    }
  },
  created () {
    if (this.record) {
      var _this = this
      setTimeout(function () {
        _this.form.setFieldsValue({
          name: _this.record.name,
          sex: _this.record.sex + '',
          account: _this.record.account
        })
      }, 1000)
    } else {
      this.disabled = false
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.account.length < 6) {
            this.$message.error('账户必须至少6字符')
            return
          }
          if (values.password.length < 8) {
            this.$message.error('密码必须至少8字符')
            return
          }
          if (values.phone.length !== 11) {
            this.$message.error('手机号格式错误')
            return
          }
          saveStaffInfo({
            account: values.account,
            pwd: md5(values.password),
            sex: values.sex,
            name: values.name,
            phone: values.phone
          }).then(res => {
            if (res.code === 0) {
              this.$message.success('保存成功')
              this.$parent.refreshTable(true)
              this.$layer.close(this.layerid)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
